<template>
  <div class="medication-allergies q-w360 q-fit">
    <question-with-error-wrap
      :error="getFieldsError(['medicationAllergies, otherMedicationAllergies'])"
    >
      <multi-answer-question
        title="label.medicationAllergies"
        hint="label.selectAllThatApply"
        :value="medicationAllergies"
        :other-value="otherMedicationAllergies"
        :options="medicationAllergiesOptions"
        @input="onFieldChange('medicationAllergies', $event)"
        @other-input="onFieldChange('otherMedicationAllergies', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { MEDICAL_ALLERGY, OTHER_VALUE_BINDINGS } from '@/modules/questionnaire/api/constants';

const MEDICATION_ALLERGIES_OPTIONS = [
  { value: MEDICAL_ALLERGY.BALSAM_OF_PERU, text: 'medicationAllergy.balsamOfPeru' },
  { value: MEDICAL_ALLERGY.TETRACYCLINE, text: 'medicationAllergy.tetracycline' },
  { value: MEDICAL_ALLERGY.DILANTIN, text: 'medicationAllergy.dilantin' },
  { value: MEDICAL_ALLERGY.TEGRETOL, text: 'medicationAllergy.tegretol' },
  { value: MEDICAL_ALLERGY.PENICILLIN, text: 'medicationAllergy.penicillin' },
  { value: MEDICAL_ALLERGY.CEPHALOSPORINS, text: 'medicationAllergy.cephalosporins' },
  { value: MEDICAL_ALLERGY.SULFONAMIDES, text: 'medicationAllergy.sulfonamides' },
  { value: MEDICAL_ALLERGY.ASPRIN, text: 'medicationAllergy.asprin' },
  { value: MEDICAL_ALLERGY.LOCAL_ANAESTHETICS, text: 'medicationAllergy.localAnaesthetics' },
  { ...OTHER_VALUE_BINDINGS, overridesAll: false, text: 'medicationAllergy.other' }
];

export default {
  name: 'MedicationAllergiesTemplate',
  components: { QuestionWithErrorWrap, MultiAnswerQuestion },
  medicationAllergiesOptions: MEDICATION_ALLERGIES_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    medicationAllergies: {
      type: Array,
      required: true
    },
    otherMedicationAllergies: {
      type: String,
      required: true
    },
    medicationTetracyclinVisible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    medicationAllergiesOptions() {
      if (this.medicationTetracyclinVisible) {
        return MEDICATION_ALLERGIES_OPTIONS;
      }

      return MEDICATION_ALLERGIES_OPTIONS.filter(
        ({ value }) => value !== MEDICAL_ALLERGY.TETRACYCLINE
      );
    }
  }
};
</script>
